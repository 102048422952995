<template>
  <Home/>
</template>

<script>
import Home from './components/Home.vue';
import "./assets/css/style.css";

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>
