<template>
  <div class="header">
    <img src="../assets/img/xway.svg" />
    <div class="main">
      <h1 class="head">ПОДБОР <span class="head-green">КЛЮЧЕВЫХ ФРАЗ</span></h1>
      <ol class="list">
        <li>Вставьте ссылку на свой товар Wildberries. </li>
        <li>Нажмите “Подобрать ключевые фразы”</li>
        <li>Скопируйте список ключевых слов и используйте их для формирования контента и настройки рекламных кампаний.</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBox"
}
</script>

<style scoped>
.header {
  color: #ffffff;
}
.main {
  max-width: 316px;
  margin-top: 148px;
}
.head {
  size: 40px;
  font-weight: 700;
}
.head-green {
  color: #27CEAA;
}
.list {
  font-size: 14px;
  font-weight: 400;
}
.list li {
  margin-bottom: 8px;
  margin-left: -20px;
}
@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px 40px 16px;
    background-color: #22222E;
  }
  .main {
    max-width: 100%;
    margin-top: 0;
  }
}
</style>