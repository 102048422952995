<template>
  <header-box />
  <footer-box />
</template>

<script>
import HeaderBox from "./Header";
import FooterBox from "./Footer";
export default {
  name: "SideBar",
  components: {
    HeaderBox,
    FooterBox,
  }
}
</script>