<template>
  <p>
    Готово! Вот ключевые фразы по данному товару:
  </p>
  <textarea
    v-model="list"
    disabled
    rows="12"
  />
  <button
    type="button"
    :class="`btn ${isCopied ? 'light-green' : 'gray'}`"
    :disabled="isCopied"
    @click="copyText"
  >
    <i
      :class="`fa ${isCopied ? 'fa-check' : 'fa-clone'}`"
    />
    {{ buttonCopyTitle }}
  </button>
  <button
    type="button"
    class="btn white"
    @click="back"
  >
    <i
      class="fa fa-long-arrow-left"
    />
    Назад
  </button>
</template>

<script>
export default {
  name: "XTextarea",
  props: {
    keywords: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    buttonCopyTitle() {
      return this.isCopied ? 'Список скопирован' : 'Скопировать список';
    },
    list() {
      return this.keywords.join('\n');
    }
  },
  data() {
    return {
      isCopied: false,
    };
  },
  methods: {
    back() {
      this.$emit('come-back');
    },
    copyText() {
      navigator.clipboard.writeText(this.keywords.join(', '))
        .then(() => {
          this.isCopied = true;
        })
        .catch(() => {
          this.isCopied = false;
        });
    },
  }
}
</script>

<style scoped>
textarea {
  outline: none;
  border: 1px solid #cacad3;
  border-radius: 4px;
  padding: 9px 12px 9px 22px;
  font-family: "Proxima Nova Rg",  Avenir, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: 65%;
  margin-bottom: 20px;
}
.btn.light-green:disabled {
  cursor: not-allowed;
}
.btn.white {
  margin-top: 5px;
}
@media (max-width: 768px) {
  textarea {
    width: 80%;
  }
}
</style>