<template>
  <p class="main-text">Вставьте ссылку на свой товар Wildberries:</p>
  <div class="main-input">
    <p class="main-input-placeholder">https://</p>
    <input
      :class="`main-input-input${linkError ? ' error' : ''}`"
      v-model="value"
      type="text"
    >
    <p
      v-if="linkError"
      class="main-input-error"
    >
      Не корректный вид ссылки
    </p>
  </div>
  <button
    type="button"
    class="btn green"
    :disabled="!value"
    @click="start"
  >
    <i
      v-if="loading"
      class="fa fa-spinner fa-spin"
    />
    {{ buttonTitle }}
  </button>
  <p
    v-if="requestError"
    class="main-input-error"
  >
    Что-то пошло не так. Попробуйте повторить снова
  </p>
</template>

<script>
export default {
  name: "XInput",
  props: {
    loading: Boolean,
    linkError: Boolean,
    requestError: Boolean,
  },
  computed: {
    buttonTitle() {
      return this.loading ? 'Подбираем…' : 'Подобрать ключевые фразы';
    }
  },
  watch: {
    value(val) {
      if (val.startsWith('https://')) {
        this.value = this.value.slice(8);
      }
    }
  },

  data() {
    return {
      value: ''
    };
  },
  methods: {
    start() {
      this.$emit('start-select', `${this.value}`);
    }
  }
}
</script>

<style scoped>
.main-text {
  font-family: "Proxima Nova Rg",  Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #3C3C47;
  margin-bottom: 10px;
}
.main-input {
  position: relative;
  display: inline;
  width: 100%;
  margin-bottom: 20px;
}
.main-input .main-input-placeholder {
  position: absolute;
  top: -8px;
  left: 10px;
  font-size: 16px;
}
.main-input .main-input-input {
  outline: none;
  border: 1px solid #cacad3;
  border-radius: 4px;
  padding: 9px 12px 9px 56px;
  font-size: 16px;
  width: 60%;
}
.main-input .main-input-input.error {
  border: 1px solid #FC1F49;
}
.main-input-error {
  color: #FC1F49;
  font-size: 14px;
  font-weight: 400;
}
.btn.green:disabled {
  cursor: not-allowed;
}
</style>