<template>
  <div class="footer">
    <p class="footer-text">Cервис на базе искусственного интеллекта, который подбирает ключевые фразы</p>
    <p class="footer-text">
      Функциональные характеристики ПО: подбор ключевых и поисковых фраз по ссылке карточки товаров на Wildberries.
    </p>
    <p class="footer-text">
      Не требует установки, сервис может быть использован в любом браузере.<br>
      Не требует специальных условий эксплуатации.<br>
      Сервис может быть использован на безвозмездных условиях.
    </p>
    <p class="footer-text">
      <a href="/offer.pdf">Договор публичной оферты</a>
    </p>
    <p class="footer-name">© ООО «Технологии продаж». 2024</p>
  </div>
</template>

<script>
export default {
  name: "FooterBox"
}
</script>

<style scoped>
.footer {
  font-size: 14px;
  font-weight: 400;
}
.footer-text {
  color: #FFFFFF80;
  max-width: 333px;
}
.footer-text a {
  color: #FFFFFF80 !important;
}
.footer-name {
  color: #FFFFFF59;
}
@media (max-width: 768px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 15px;
    background-color: #22222E;
  }
}
</style>