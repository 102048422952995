<template>
  <div class="content">
    <x-input
      v-if="keywords && !keywords.length"
      :loading="loading"
      :link-error="linkError"
      :request-error="requestError"
      @start-select="startSelect"
    />
    <x-textarea
      v-else
      :keywords="keywords"
      @come-back="comeBack"
    />
  </div>
</template>

<script>
import XInput from "./XInput";
import XTextarea from "./XTextarea";

export default {
  name: "ContentBox",
  props: {
    loading: Boolean,
    linkError: Boolean,
    requestError: Boolean,
    keywords: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    XInput,
    XTextarea,
  },
  methods: {
    startSelect(value) {
      this.$emit('start-select', value);
    },
    comeBack() {
      this.$emit('come-back');
    },
  }
}
</script>

<style scoped>
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 115px;
}
@media (max-width: 768px) {
  .content {
    padding: 20px;
    justify-content: flex-start;
  }
}
</style>