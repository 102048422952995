<template>
  <div class="home">
    <header-box class="home-header"/>
    <div class="info">
      <side-bar />
    </div>
    <content-box
      :keywords="keywords"
      :loading="isLoading"
      :link-error="linkError"
      :request-error="requestError"
      @start-select="startSelect"
      @come-back="comeBack"
    />
    <footer-box class="home-header" />
  </div>
</template>

<script>
import SideBar from "./SideBar";
import HeaderBox from "./Header";
import ContentBox from "./Content";
import FooterBox from "./Footer";

export default {
  name: "HomePage",
  components: {
    HeaderBox,
    ContentBox,
    FooterBox,
    SideBar
  },
  data() {
    return {
      isLoading: false,
      keywords: [],
      linkError: false,
      requestError: false,
    }
  },
  methods: {
    startSelect(value) {
      this.linkError = false;
      this.requestError = false;
      const arrFromLink = value.split('/');
      if (!arrFromLink[0].includes('wildberries.ru')) {
        this.linkError = true;
        return;
      }
      this.linkError = false;
      const productId = arrFromLink[1] === 'catalog' || arrFromLink[1] === 'offer' ? arrFromLink[2] : arrFromLink[1];
      if (/^\d+$/.test(productId)) {
        this.getKeywords(productId);
      } else {
        this.linkError = true;
        return;
      }
    },
    comeBack() {
      this.keywords = [];
    },

    async getKeywords(id) {
      try {
        this.isLoading = true;
        let url = `https://keywords.aliway.ru/keywords?wbid=${id}&alg=1`;
        let response = await fetch(url);

        let data = await response.json();
        if (data.success) {
          this.keywords = data.keywords;
        }
      } catch (e) {
        this.requestError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.home {
  width: 100vw;
  min-height: 100vh;
  display: flex;
}
.info {
  width: 25%;
  background-color: #22222E;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #FFFFFF;
}
.home-header {
  display: none;
}
@media (max-width: 768px) {
  .info {
    display: none;
  }
  .home {
    flex-direction: column;
  }
  .home-header {
    display: block;
  }
}
</style>